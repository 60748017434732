



















































































import { Component, Vue } from 'vue-property-decorator'
import { hasPermissions } from '@/utils/auth'
import { EventBus } from '@/utils/event-bus'
import AddNewProductModal from './AddNewProduct.modal.vue'
import { Product } from '@/store/modules/product'

@Component({
  components: {
    AddNewProductModal
  }
})
export default class AdminDashboard extends Vue {
  breadcrumbItems: any[] = [
    {
      text: 'Admin',
      to: { name: 'admin' }
    }
  ]

  get products () {
    return this.$store.getters.getProducts.sort((a: Product, b: Product) => { return a.name < b.name ? -1 : a.name > b.name ? 1 : 0 })
  }

  hasPermissions = hasPermissions

  async mounted () {
    await this.$store.dispatch('loadProductsIncDisabled', this.$store.getters.getSelectedMarket)
    EventBus.$on('market-selected', async () => {
      if (this.$route.name !== 'admin') {
        return
      }
      await this.$nextTick()
      await this.$store.dispatch('loadProductsIncDisabled', this.$store.getters.getSelectedMarket)
    })
  }

  async addNewProduct () {
    await this.$store.dispatch('loadMarkets')
    await this.$store.dispatch('loadRoles')
    this.$bvModal.show('add-new-product-modal')
  }
}
