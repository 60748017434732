

























































import { Component, Vue } from 'vue-property-decorator'
import { Role } from '@/store/modules/role'
import { Market } from '@/store/modules/market'

@Component
export default class AddNewProductModal extends Vue {
  product: any = {
    name: '',
    description: '',
    brandPrimaryColor: '#fdb051',
    disabled: true,
    markets: []
  }

  selectedMarkets: string[] = []
  selectedRoles: string[] = []

  get marketOptions () {
    return this.$store.getters.getMarkets
  }

  get roleOptions () {
    return this.$store.getters.getRoles
  }

  marketsChanged () {
    this.product.markets = this.marketOptions.filter((m: Market) => {
      return this.selectedMarkets.includes(m.key)
    })
  }

  rolesChanged () {
    this.product.roles = this.roleOptions.filter((r: Role) => {
      return this.selectedRoles.includes(r.key)
    })
  }

  confirmAddProduct () {
    if (!this.selectedRoles.length) {
      this.$bvModal.msgBoxOk('Please select at least one role to associate with this product.', {
        title: 'Error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        centered: true
      })
      return
    }
    if (!this.selectedMarkets.length) {
      this.$bvModal.msgBoxOk('Please select at least one market to associate with this product.', {
        title: 'Error',
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'error',
        centered: true
      })
      return
    }
    this.$bvModal.msgBoxConfirm('Are you sure you wish to add this product?', {
      size: 'sm',
      buttonSize: 'sm',
      okTitle: 'YES',
      cancelTitle: 'NO',
      footerClass: 'p-2',
      hideHeaderClose: false,
      centered: true
    }).then(value => {
      if (!value) {
        return
      }
      this.$store.dispatch('addNewProduct', this.product).then(() => {
        this.$bvModal.hide('add-new-product-modal')
        this.$store.dispatch('fetchNewAccessToken').then((accessToken: any) => {
          // handler for if api fails entirely:
          if (accessToken === null) {
            // console.log('accessToken === null')
            this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
            return
          }
          // refetch now we have permissions
          this.$store.dispatch('loadProductsAdmin', this.$store.getters.getSelectedMarket)
          // on successfully getting token:
        }).catch(() => {
          console.log('fetchNewAccessToken fail')
          this.$router.push({ name: 'login', query: { redirect: this.$route.path } })
        })
      })
    })
  }

  closeModal () {
    this.$bvModal.hide('add-new-product-modal')
  }
}
